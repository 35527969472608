import { render, staticRenderFns } from "./importer-successfully-modal.vue?vue&type=template&id=5fa59300&scoped=true&"
import script from "./importer-successfully-modal.vue?vue&type=script&lang=js&"
export * from "./importer-successfully-modal.vue?vue&type=script&lang=js&"
import style0 from "./importer-successfully-modal.vue?vue&type=style&index=0&id=5fa59300&lang=scss&scoped=true&"
import style1 from "./importer-successfully-modal.vue?vue&type=style&index=1&id=5fa59300&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fa59300",
  null
  
)

export default component.exports
import {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn,ClosePopup} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QDialog,QCard,QCardSection,QIcon,QCardActions,QBtn})
component.options.directives = Object.assign(Object.create(component.options.directives || null), component.options.directives || {}, {ClosePopup})
