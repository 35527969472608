//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';

export default {
  name: 'app-importer-form-print',
  props: ['visible', 'saveLoading', 'printingLoading'],


  computed: {
    ...mapGetters({
      is_screen_xs: 'layout/is_screen_xs',
    }),
  },
  methods: {
    i18n(key, args) {
      return this.$t(key, args);
    },
  },
};
